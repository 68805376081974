import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetFranchises_DetailToInit, setFranchises_DetailMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteFranchises_Detail } from "services/franchises_detailService";
import { detailLocations } from "services/locationsService";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import Parser from 'html-react-parser';
import DOMPurify from "dompurify";


type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Franchises_DetailTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {

    const location = useLocation();
    const navigate = useNavigate();
    let uri = location.pathname;

    let parts = uri.split("/");
    let query = parts[2];

    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);

    const [tipoFranquicia, settipoFranquicia] = useState("N/A");
    const [tipoVivienda, settipoVivienda] = useState("0");
    const [tipoCorporativa, settipoCorporativa] = useState("0");
    const [tipoServicioPublico, settipoServicioPublico] = useState("0");

    const rData = useSelector((state: RootState) => state.franchises_detail);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetFranchises_DetailToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    }, [rData.list.length])
    
    useEffect(() => {
        detailLocations(query).then((response) => {
            if(response.data.records.length > 1){
                settipoFranquicia("Mixta");
            }else{
                settipoFranquicia(response.data.records[0].property_type);
            }
            
            response.data.records.map( (r, i) => {
                
                if(r.property_type == 'De vivienda'){
                    settipoVivienda(r.t);
                }
                if(r.property_type == 'Corporativa'){
                    settipoCorporativa(r.t);
                }
                if(r.property_type == 'Servicio Publico'){
                    settipoServicioPublico(r.t);
                }

            });
            
            return '';
        })


      },[])

    const handleReset = () => {
        dispatch(resetFranchises_DetailToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteFranchises_Detail(rowData.id);
            if (response) {
                dispatch(resetFranchises_DetailToInit());
                dispatch(setFranchises_DetailMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setFranchises_DetailMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setFranchises_DetailMessage(''));
        hideShowForm('add');
    }

    const getInformation = (r) => {
        const table = ( <table className="table text-white"> 
                            <tr>
                                <td>TOTAL ACTIVOS FLINTSTONE´S AIRSHIPS LLC</td>
                                <td>{r.llc_total_activos}</td>
                            </tr>
                            <tr>
                                <td>VALOR DE ACTIVOS FLINTSTONES</td>
                                <td>{r.llc_valor_activos}</td>
                            </tr>
                            <tr>
                                <td>ONBOARDING JURIDICO Y DIGITAL</td>
                                <td>{r.llc_onboarding}</td>
                            </tr>
                            <tr>
                                <td>INGRESO AÑO 1 LLC</td>
                                <td>{r.llc_ingreso_ano1}</td>
                            </tr>
                            <tr>
                                <td>REVENUE ESPERADO</td>
                                <td>{r.llc_revenue}</td>
                            </tr>
                            <tr>
                                <td>GASTOS (TAX, SEGURO, PM)</td>
                                <td>{r.llc_gastos}</td>
                            </tr>
                            <tr>
                                <td>RESERVAS</td>
                                <td>{r.llc_reserva}</td>
                            </tr>
                            <tr>
                                <td>MANAGEMENT CONTABLE LLC SFH</td>
                                <td>{r.llc_management}</td>
                            </tr>
                            <tr>
                                <td>UTILIDAD NETA LLC PRS</td>
                                <td>{r.llc_utilidad}</td>
                            </tr>
                            <tr>
                                <td>ROI NETO PRS</td>
                                <td>{r.llc_roi}</td>
                            </tr>
                            <tr>
                                <td>UTILIDAD NETA LLC OPTIMO</td>
                                <td>{r.llc_utilidad_neta}</td>
                            </tr>
                            <tr>
                                <td>ROI NETO LLC OPTIMO</td>
                                <td>{r.llc_roi_neto}</td>
                            </tr>
                            <tr>
                                <td>REVALORIZACION ESTIMADA 5 AÑOS</td>
                                <td>{r.llc_revalorizacion_estimada}</td>
                            </tr>
                        </table>);
        return table;
    }
    const getTokenomics = (r) => {
        const table = ( <table className="table text-white"> 
                            <tr>
                                <td>VALOR DEL TICKET</td>
                                <td>{r.tk_valor_ticket}</td>
                            </tr>
                            <tr>
                                <td>TOTAL SOCIOS</td>
                                <td>{r.tk_total_socios}</td>
                            </tr>
                            <tr>
                                <td>TOTAL PARTICIPACIONES ENTREGADAS MAX FQ</td>
                                <td>{r.tk_total_particicaciones}</td>
                            </tr>
                            <tr>
                                <td>PARTICIPACION ACCIONARIA POR TICKET</td>
                                <td>{r.tk_participacion_ticket}</td>
                            </tr>
                            <tr>
                                <td>INGRESOS POR TICKET</td>
                                <td>{r.tk_ingresos_ticket}</td>
                            </tr>
                            <tr>
                                <td>REVENUE ESPERADO</td>
                                <td>{r.tk_revenue_esperado}</td>
                            </tr>
                            <tr>
                                <td>UTILIDAD NETA LLC PRS</td>
                                <td>{r.tk_utilidad_neta}</td>
                            </tr>
                            <tr>
                                <td>ROI NETO PRS</td>
                                <td>{r.tk_roi_neto}</td>
                            </tr>
                            <tr>
                                <td>UTILIDAD NETA LLC OPTIMO</td>
                                <td>{r.tk_utilidad_neta_optimo}</td>
                            </tr>
                            <tr>
                                <td>ROI NETO LLC OPTIMO</td>
                                <td>{r.tk_roi_neto_optimo}</td>
                            </tr>
                            <tr>
                                <td>REVALORIZACION ESTIMADA 5 AÑOS</td>
                                <td>{r.tk_revalorizacion_estimada}</td>
                            </tr>
        </table>);
        return table;

    }

    const columns = [
{name: 'id', selector: row => row.id, sortable: true},
{name: 'franchise_id', selector: row => row.franchise_id, sortable: true},
{name: 'profit_rent', selector: row => row.profit_rent, sortable: true},
{name: 'sell_rent', selector: row => row.sell_rent, sortable: true},
{name: 'token_price', selector: row => row.token_price, sortable: true},
{name: 'int_token', selector: row => row.int_token, sortable: true},
{name: 'total_tokens', selector: row => row.total_tokens, sortable: true},
{name: 'esi_validation', selector: row => row.esi_validation, sortable: true},
{name: 'documentation', selector: row => row.documentation, sortable: true},
{name: 'doc_emision', selector: row => row.doc_emision, sortable: true},
{name: 'plans', selector: row => row.plans, sortable: true},
{name: 'created_at', selector: row => row.created_at, sortable: true},
{ name: 'title', selector: row => row.title, sortable: false},
{ name: 'type_info', selector: row => row.type_info, sortable: false},
{ name: 'llc_total_activos', selector: row => row.llc_total_activos, sortable: false},
{ name: 'llc_valor_activos', selector: row => row.llc_valor_activos, sortable: false},
{ name: 'llc_onboarding', selector: row => row.llc_onboarding, sortable: false},
{ name: 'llc_ingreso_ano1', selector: row => row.llc_ingreso_ano1, sortable: false},
{ name: 'llc_revenue', selector: row => row.llc_revenue, sortable: false},
{ name: 'llc_gastos', selector: row => row.llc_gastos, sortable: false},
{ name: 'llc_reserva', selector: row => row.llc_reserva, sortable: false},
{ name: 'llc_management', selector: row => row.llc_management, sortable: false},
{ name: 'llc_utilidad', selector: row => row.llc_utilidad, sortable: false},
{ name: 'llc_roi', selector: row => row.llc_roi, sortable: false},
{ name: 'llc_utilidad_neta', selector: row => row.llc_utilidad_neta, sortable: false},
{ name: 'llc_roi_neto', selector: row => row.llc_roi_neto, sortable: false},
{ name: 'llc_revalorizacion_estimada', selector: row => row.llc_revalorizacion_estimada, sortable: false},
{ name: 'tk_valor_ticket', selector: row => row.tk_valor_ticket, sortable: false},
{ name: 'tk_total_socios', selector: row => row.tk_total_socios, sortable: false},
{ name: 'tk_total_particicaciones', selector: row => row.tk_total_particicaciones, sortable: false},
{ name: 'tk_participacion_ticket', selector: row => row.tk_participacion_ticket, sortable: false},
{ name: 'tk_ingresos_ticket', selector: row => row.tk_ingresos_ticket, sortable: false},
{ name: 'tk_revenue_esperado', selector: row => row.tk_revenue_esperado, sortable: false},
{ name: 'tk_utilidad_neta', selector: row => row.tk_utilidad_neta, sortable: false},
{ name: 'tk_roi_neto', selector: row => row.tk_roi_neto, sortable: false},
{ name: 'tk_utilidad_neta_optimo', selector: row => row.tk_utilidad_neta_optimo, sortable: false},
{ name: 'tk_roi_neto_optimo', selector: row => row.tk_roi_neto_optimo, sortable: false},
{ name: 'tk_revalorizacion_estimada', selector: row => row.tk_revalorizacion_estimada, sortable: false},


        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];

    const styleTableLeft = {
        width:"220px",
        fontSize:"15px",
        height:"18px",
        paddingTop:"0px",
        paddingBottom:"0px",
        borderBottom:"1px dashed #FFF",
    }
    const styleTableRight = {
        width:"100px",
        fontSize:"15px",
        height:"18px",
        paddingTop:"2px",
        paddingBottom:"0px",
    }
    const styleMidCol = {
        borderBottom:"1px dashed #FFF",
    }

    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const name = String(Object.values(userProfile)[1]);

    const functionButtons = (row) => {
        if(String(Object.values(userProfile)[6]) == "1"){
            return (
                <>
                    <Button variant="link" className="btn-sm text-white btn-info m-r-20" onClick={() => handleRowEdit(row)}>Edit</Button>
                    <Button variant="link" className="btn-sm text-white btn-danger" onClick={() => handleRowDeleteClick(row)}>Delete</Button>
                </>
            )
        }else{
            return "";
        }
    }
    return (
        <>
        <div className="row">
        {
            rData.list.map( (r, i) => (<>  
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body p-30" >
                            <h5 className="mb-30">{r.title}</h5>
                            {r.type_info === '1' ? getInformation(r) :   getTokenomics(r) }
                            {functionButtons(r)}
                        </div>
                    </div>
                </div>            
            </>))    
        }
        </div>
        <Card className="shadow mb-4">
            <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        </Card>
        </>
    );
}

