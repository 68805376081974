import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import {Link} from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetFranchisesToInit, setFranchisesMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup,Tab } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteFranchises } from "services/franchisesService";
import Parser from 'html-react-parser';
import { useNavigate } from "react-router";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    handleRowView: (row) => void
    getData: (page, pageSize, searchKey) => void;
};
export const FranchisesTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, handleRowView, getData }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.franchises);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetFranchisesToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    }, [rData.list.length])

    const DetailFranchise = (path) => {

        const newpath = '/franchises_detail/'+path;
        navigate(newpath, { replace: true });

    }


    const handleReset = () => {
        dispatch(resetFranchisesToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteFranchises(rowData.id);
            if (response) {
                dispatch(resetFranchisesToInit());
                dispatch(setFranchisesMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setFranchisesMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setFranchisesMessage(''));
        hideShowForm('add');
    }
    const Stars = (num) => {
        
        num = parseInt(num);
        var items = "";
        num = 1;
        for (var k = 0; k < num; k++) {
            items += '<div className="personalizedicon m-r-5"><div className="icon_PropiedadesColor_icon w-10"></div></div> ';
        }
        return items;
    }

    const columns = [
        {name: 'id', selector: row => row.id, sortable: true},
        {name: 'name', selector: row => row.name, sortable: true},
        {name: 'country', selector: row => row.country, sortable: true},
        {name: 'city', selector: row => row.city, sortable: true},
        {name: 'description', selector: row => row.description, sortable: true},
        {name: 'rents', selector: row => row.rents, sortable: true},
        {name: 'pricing_list', selector: row => row.pricing_list, sortable: true},
        {name: 'discounts', selector: row => row.discounts, sortable: true},
        {name: 'min_invest', selector: row => row.min_invest, sortable: true},
        {name: 'max_invest', selector: row => row.max_invest, sortable: true},
        {name: 'expexted_profit', selector: row => row.expexted_profit, sortable: true},
        {name: 'franchise_type', selector: row => row.franchise_type, sortable: true},
        {name: 'created_at', selector: row => row.created_at, sortable: true},
        {name: 'avatar', selector: row => row.avatar, sortable: true},
        {name: 'qualification', selector: row => row.qualification, sortable: true},

        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowView(row)}>View</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];

    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const name = String(Object.values(userProfile)[1]);

    const showButtons = (r) => {
        // IF IS ADMIN
        if (String(Object.values(userProfile)[6]) == '1'){

            return  ( 
                <>
                    <Button variant="link" className="justify-content-end btn text-white m-r-10 btn-primary btn-rent-invert" onClick={() => DetailFranchise(r.id)}>Detalles</Button>
                    <Button variant="link" className="justify-content-end btn text-white m-r-10 btn-info" onClick={() => handleRowEdit(r)}>Edit</Button>
                    <Button variant="link" className="justify-content-end btn text-white m-r-10 btn-danger" onClick={() => handleRowDeleteClick(r)}>Delete</Button>
                </>
                    )
        }else{
            if (r.status == '1'){
                return  ( 
                    <>
                        <Button variant="link" className="btn text-white m-r-10 btn-primary btn-rent-invert" onClick={() => DetailFranchise(r.id)}>Detalles</Button>
                    </>
                    )
            }else{
                return  ( 
                    <>
                        <Button variant="link" disabled className="btn text-white m-r-10 btn-primary btn-rent-invert" >Próximamente</Button>
                    </>
                    )
            }
        }            
    }

    // const buttonAdd = () => {
    //     if (String(Object.values(userProfile)[6]) == '1'){   
    //         return (
    //                 <div className="row">
    //                     <div className="col-lg-12 m-b-30">
    //                         <Button className="btn-icon-split float-right btn-rent-invert b-0" onClick={handleAddButtonClick}>
    //                             <span className="icon text-white-50">
    //                                 <i className="fas fa-add"></i>
    //                             </span>
    //                             <span className="text">Add New</span>
    //                         </Button>
    //                     </div>
    //                 </div>
    //         )

    //     }else{
    //         return 'hola';
    //     }
    // }
    return (
    <>
        {/* { buttonAdd } */}
        <div className="">
            <div className="">
                {/* <Button className="btn-icon-split float-right btn-rent-invert b-0" onClick={handleAddButtonClick}>
                    <span className="icon text-white-50">
                        <i className="fas fa-add"></i>
                    </span>
                    <span className="text">Add New</span>
                </Button> */}
            </div>
        </div>

        <div className="row">
        {
            rData.list.map( (r, i) => (<>
                
                <div className="col-lg-6 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body p-30">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12 col-sm-12">
                                    <div className="product-detail-content">
                                        <div className="new-arrival-content pr">
                                            <div className="d-flex">
                                                <h4 className="m-r-20">{r.name} {r.city}, {r.country}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12 col-sm-12">
                                {/* Tab panes */}
                                    <Tab.Container defaultActiveKey="first">
                    
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <div className="contenedor">
                                                    <figure>
                                                        <img src={r.avatar} className="img-fluid" />
                                                    </figure>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                        <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
                                            
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                            <div className="row">     

                                <div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12 col-sm-12">
                                    <div className="product-detail-content">
                                        {/*Product details*/}
                                        <div className="new-arrival-content pr">
                                            {/* <div className="comment-review star-rating d-flex">
                                                <div className="fs-15">
                                                    <ul className="m-l-10 p-l-0">
                                                    {Parser(Stars(r.qualification))}
                                                    </ul>
                                                </div>
                                            </div> */}
                                            <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_RentLogoColor01_icon w-30"></div> </div> Rents:{" "}<span className="item text-white"> {r.rents}</span></p>
                                            <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_PropiedadesColor_icon w-10"></div> </div> Propiedades:{" "}<span className="item text-white"> {r.qualification}</span></p>    
                                            
                                            <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_PrecioSugeridoColor_Icon w-15"></div> </div> Precio de Lista:{" "}<span className="item text-white"> ${r.pricing_list}</span></p>
                                            <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_DescuentosColor_Icon w-15"></div> </div> Rentabilidad Esperada:{" "}<span className="item text-white"> {" "}{r.discounts}</span></p>
                                            <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_InversionMinimaColor_Icon w-15"></div> </div> Inversión Minima: <span className="item text-white">{r.min_invest} USD</span>{" "}</p>
                                            {/* <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_RentaAnualEstimadaColor_Icon w-15"></div> </div> Rentabilidad Esperada: <span className="item text-white">{r.expexted_profit}%</span></p> */}
                                            
                                            
                                        {/*Quanatity End*/}
                                            <div>
                                                <div className="shopping-cart mt-3 d-flex justify-content-end">
                                                    { showButtons(r) }
                                                    {/* { Parser( showButtons(r) ) } */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            ))    
        }
        </div>
        <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
    </>    
    );
}

