export const MenuItems = [
    { title: 'Dashboard', path: '/dashboard', icon: 'icon_DashboardBlack_icon w-20 invert-100',  textClass:'', altIcon : '', subMenu: [] },
    { title: 'Franquicias Inmobiliarias', path: '/franchises', icon: 'icon_PropiedadesBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    { title: 'Mercados Secundarios', path: '/soon/mercados', icon: 'icon_MercadosSecundariosBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    { title: 'Mis Franquicias (LLC)', path: '/nfts', icon: 'icon_MisNftBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    { title: 'Ofertas', path: '/soon/ofertas', icon: 'icon_MercadosSecundariosBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    // { title: 'Staking', path: '/soon/staking', icon: 'icon_StakingBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    { title: 'Transacciones', path: '/item_activities', icon: 'icon_TransaccionesBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    // { title: 'Conce a Rent', path: '/', icon: 'icon_RentSimboloBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: [
    //     { title: 'Descripción del Token', path: '/aboutrent', icon: 'icon_OtrasCuentasBlack_icon w-20 invert-100'},
    //     { title: 'Roadmap', path: '/roadmap', icon: 'icon_OtrasCuentasBlack_icon w-20 invert-100'}
    // ]},
    // { title: 'Referidos', path: '/referrals', icon: 'icon_ClientesBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    // { title: 'Mis Votaciones', path: '/franchises', icon: 'icon_MisVotacionesBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    { title: 'Comisiones', path: '/soon/comisiones', icon: 'icon_StripeBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    // { title: 'Littio', path: '/soon/littio', icon: 'icon_StripeBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    { title: 'Cuentas financieras', path: '/soon/cuentas', icon: 'icon_StripeBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
    // { title: 'Mi familia', path: '/soon/familia', icon: 'icon_StripeBlack_icon w-20 invert-100', textClass:'', altIcon : '', subMenu: []},
]
