import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetNftsToInit, setNftsMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup,Tab,NavLink } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteNfts } from "services/nftsService";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const NftsTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.nfts);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetNftsToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    }, [rData.list.length])
    const handleReset = () => {
        dispatch(resetNftsToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteNfts(rowData.id);
            if (response) {
                dispatch(resetNftsToInit());
                dispatch(setNftsMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setNftsMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setNftsMessage(''));
        hideShowForm('add');
    }

    const columns = [
        {name: 'id', selector: row => row.id, sortable: true},
{name: 'file', selector: row => row.file, sortable: true},
{name: 'contact', selector: row => row.contact, sortable: true},
{name: 'description', selector: row => row.description, sortable: true},
{name: 'author', selector: row => row.author, sortable: true},
{name: 'owner', selector: row => row.owner, sortable: true},
{name: 'status', selector: row => row.status, sortable: true},
{name: 'views', selector: row => row.views, sortable: true},
{name: 'favorites', selector: row => row.favorites, sortable: true},
{name: 'price', selector: row => row.price, sortable: true},
{name: 'sale_date', selector: row => row.sale_date, sortable: true},
{name: 'contact_address', selector: row => row.contact_address, sortable: true},
{name: 'token_id', selector: row => row.token_id, sortable: true},
{name: 'token_standard', selector: row => row.token_standard, sortable: true},
{name: 'blockchain', selector: row => row.blockchain, sortable: true},
{name: 'metadata', selector: row => row.metadata, sortable: true},
{name: 'franchise_id', selector: row => row.franchise_id, sortable: true},
{name: 'created_at', selector: row => row.created_at, sortable: true},
{name: 'updated_at', selector: row => row.updated_at, sortable: true},
{name: 'deleted_at', selector: row => row.deleted_at, sortable: true},

        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];

    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")): undefined;
    const name = String(Object.values(userProfile)[1]);

    const functionButtons = (row) => {

        if(String(Object.values(userProfile)[6]) == "1"){
            return (
                <>
                    <Button variant="link" className="btn-sm text-white btn-info m-r-20" onClick={() => handleRowEdit(row)}>Edit</Button>
                    <Button variant="link" className="btn-sm text-white btn-danger" onClick={() => handleRowDeleteClick(row)}>Delete</Button>
                </>
            )
        }else{
            return "";
        }

    }

    const [state, setState] = useState({ isOpen: false });
    const [imageFile, setImageFile] = useState("");
    const [descriptionFile, setDescriptionFile] = useState("");
    const handleShowDialog = (img, description) => {
        setImageFile(img);
        setDescriptionFile(description);
        setState({ isOpen: !state.isOpen });
        console.log("cliked");
    };

    const handleViewNft = (id) => {
        const newpath = '/collection/'+id;
        navigate(newpath, { replace: true });
    }

    return (
        <>
        
        <div className="row">
        {
            rData.list.map( (r, i) => (<>
                
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="card-body p-30 p-t-20">
                            <div className="row">
                                <div className="col-xl-12 p-0">
                                    <div className="contenedor">
                                        <figure>
                                        <img
                                            className="img-fluid"
                                            src={r.file}
                                            onClick={() =>  handleShowDialog(r.file, r.description)}
                                            alt="no image"
                                            />
                                            {/* <div className="capa"></div> */}
                                        </figure>
                                    </div>
                                    <p className="text-rent"> 
                                        <NavLink className='nav-link pl-0 pt-0 btn btn-link text-left' data-toggle="collapse" onClick={() => handleViewNft(r.id)}>
                                            <div className="personalizedicon m-r-5"> <div className="icon_UbicationColor_icon w-15"></div> </div><span className="item text-white">LLC:{" "}{r.description}</span>
                                        </NavLink>
                                    </p>
                                    <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_PropiedadesColor_icon w-15"></div> </div>Precio sugerido: <span className="item text-white">{r.price} RENT</span></p>
                                    <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_UbicationColor_icon w-15"></div> </div>Precio promedio:{" "}<span className="item text-white"> {r.price} RENT</span></p>
                                    <p className="text-rent"> <div className="personalizedicon m-r-5"> <div className="icon_PropiedadesColor_icon w-15"></div> </div>Rentabilidad: <span className="item text-white">{" "}</span></p>
                                    {functionButtons(r)}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            ))    
        }
        </div>
        {state.isOpen && (
        <dialog
            className="dialog"
            open
            onClick={() => handleShowDialog("", "")}
        >
            <img
            className="image"
            src={imageFile}
            onClick={() => handleShowDialog("", "")}
            alt="no image"
            />
            <p className="text-rent m-t-10"> <div className="personalizedicon m-r-5"> <div className="icon_UbicationColor_icon w-15"></div> </div>{descriptionFile}</p>
        </dialog>
        )}
        <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        </>
    );
}

