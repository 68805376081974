import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setNftsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addNfts, updateNfts } from "services/nftsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const NftsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',file:'',contact:'',description:'',author:'',owner:'',status:'',views:'',favorites:'',price:'',sale_date:'',contact_address:'',token_id:'',token_standard:'',blockchain:'',metadata:'',franchise_id:'',created_at:'',updated_at:'',deleted_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateNfts(values.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setNftsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setNftsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addNfts(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setNftsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setNftsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
file: yup.number().required('file is required'),
contact: yup.number().required('contact is required'),
description: yup.number().required('description is required'),
author: yup.number().required('author is required'),
owner: yup.number().required('owner is required'),
status: yup.number().required('status is required'),
views: yup.number(),
favorites: yup.number(),
price: yup.number().required('price is required'),
sale_date: yup.date().required('sale_date is required'),
contact_address: yup.string(),
token_id: yup.string(),
token_standard: yup.string(),
blockchain: yup.string(),
metadata: yup.string().required('metadata is required'),
franchise_id: yup.number().required('franchise_id is required'),
created_at: yup.date().required('created_at is required'),
updated_at: yup.date(),
deleted_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Nfts
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Nfts</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">file</label>
<Form.Control type="number" name="file" className="form-control" value={formik.values.file}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.file && !!formik.errors.file}
isValid ={!!formik.touched.file && !formik.errors.file}
></Form.Control>
{
    formik.errors.file && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.file}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">contact</label>
<Form.Control type="number" name="contact" className="form-control" value={formik.values.contact}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.contact && !!formik.errors.contact}
isValid ={!!formik.touched.contact && !formik.errors.contact}
></Form.Control>
{
    formik.errors.contact && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.contact}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">description</label>
<Form.Control type="number" name="description" className="form-control" value={formik.values.description}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.description && !!formik.errors.description}
isValid ={!!formik.touched.description && !formik.errors.description}
></Form.Control>
{
    formik.errors.description && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.description}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">author</label>
<Form.Control type="number" name="author" className="form-control" value={formik.values.author}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.author && !!formik.errors.author}
isValid ={!!formik.touched.author && !formik.errors.author}
></Form.Control>
{
    formik.errors.author && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.author}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">owner</label>
<Form.Control type="number" name="owner" className="form-control" value={formik.values.owner}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.owner && !!formik.errors.owner}
isValid ={!!formik.touched.owner && !formik.errors.owner}
></Form.Control>
{
    formik.errors.owner && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.owner}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">status</label>
<Form.Control type="number" name="status" className="form-control" value={formik.values.status}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.status && !!formik.errors.status}
isValid ={!!formik.touched.status && !formik.errors.status}
></Form.Control>
{
    formik.errors.status && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.status}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">views</label>
<Form.Control type="number" name="views" className="form-control" value={formik.values.views}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.views && !!formik.errors.views}
isValid ={!!formik.touched.views && !formik.errors.views}
></Form.Control>
{
    formik.errors.views && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.views}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">favorites</label>
<Form.Control type="number" name="favorites" className="form-control" value={formik.values.favorites}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.favorites && !!formik.errors.favorites}
isValid ={!!formik.touched.favorites && !formik.errors.favorites}
></Form.Control>
{
    formik.errors.favorites && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.favorites}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">price</label>
<Form.Control type="number" name="price" className="form-control" value={formik.values.price}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.price && !!formik.errors.price}
isValid ={!!formik.touched.price && !formik.errors.price}
></Form.Control>
{
    formik.errors.price && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.price}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">sale_date</label>
<Form.Control type="date" name="sale_date" className="form-control" value={formik.values.sale_date}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.sale_date && !!formik.errors.sale_date}
isValid ={!!formik.touched.sale_date && !formik.errors.sale_date}
></Form.Control>
{
    formik.errors.sale_date && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.sale_date}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">contact_address</label>
<Form.Control type="text" name="contact_address" className="form-control" value={formik.values.contact_address}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.contact_address && !!formik.errors.contact_address}
isValid ={!!formik.touched.contact_address && !formik.errors.contact_address}
></Form.Control>
{
    formik.errors.contact_address && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.contact_address}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">token_id</label>
<Form.Control type="text" name="token_id" className="form-control" value={formik.values.token_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.token_id && !!formik.errors.token_id}
isValid ={!!formik.touched.token_id && !formik.errors.token_id}
></Form.Control>
{
    formik.errors.token_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.token_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">token_standard</label>
<Form.Control type="text" name="token_standard" className="form-control" value={formik.values.token_standard}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.token_standard && !!formik.errors.token_standard}
isValid ={!!formik.touched.token_standard && !formik.errors.token_standard}
></Form.Control>
{
    formik.errors.token_standard && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.token_standard}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">blockchain</label>
<Form.Control type="text" name="blockchain" className="form-control" value={formik.values.blockchain}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.blockchain && !!formik.errors.blockchain}
isValid ={!!formik.touched.blockchain && !formik.errors.blockchain}
></Form.Control>
{
    formik.errors.blockchain && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.blockchain}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">metadata</label>
<Form.Control type="text" name="metadata" className="form-control" value={formik.values.metadata}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.metadata && !!formik.errors.metadata}
isValid ={!!formik.touched.metadata && !formik.errors.metadata}
></Form.Control>
{
    formik.errors.metadata && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.metadata}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">franchise_id</label>
<Form.Control type="number" name="franchise_id" className="form-control" value={formik.values.franchise_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.franchise_id && !!formik.errors.franchise_id}
isValid ={!!formik.touched.franchise_id && !formik.errors.franchise_id}
></Form.Control>
{
    formik.errors.franchise_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.franchise_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="date" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="date" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">deleted_at</label>
<Form.Control type="date" name="deleted_at" className="form-control" value={formik.values.deleted_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.deleted_at && !!formik.errors.deleted_at}
isValid ={!!formik.touched.deleted_at && !formik.errors.deleted_at}
></Form.Control>
{
    formik.errors.deleted_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.deleted_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

