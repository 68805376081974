import { APIService } from "services";

export const getNfts = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllNfts(pageNo,pageSize);
    }
    else{
        try {
            res = await searchNfts(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addNfts = (data) => {
return APIService.api().post(`/nfts`,data)
}
export const updateNfts = (id,data) => {
return APIService.api().patch(`/nfts/${id}`,data)
}
export const getAllNfts = (pageNo,pageSize) => {
return APIService.api().get(`/nfts/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneNfts = (id) => {
return APIService.api().get(`/nfts/${id}`)
}
export const searchNfts = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/nfts/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteNfts = (id) => {
return APIService.api().delete(`/nfts/${id}`)
}
export const getMyNfts = (searchKey) => {
    return APIService.api().get(`/nfts/getmine/${searchKey}`)
}

export const getOwnsNfts = async (search) => {
    let res;

    try {
        res = await getMyNfts(search);
    } catch(err) {
            return { records:[], totalCount:0 }
    }

    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getParticipations = async() => {
    return APIService.api().get(`/nfts/report/participations`)
}

export const getRentability = async() => {
    return APIService.api().get(`/nfts/report/rentability`)
}
